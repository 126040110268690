import routes from 'helpers/routes';
import {
  useBodyClassName,
  useCurrentPublisher,
  useCurrentSuggester,
} from 'hooks';
import useIsMobile from 'hooks/useIsMobile';
import {
  IconAthleteFilled,
  IconInboxFilled,
  IconAthleteOutline,
  IconInboxOutline,
  IconMediaStacked,
  IconMediaStackedSolid,
} from 'icons';
import React, { createElement, useState } from 'react';
import GlobalNavContactModal from './components/GlobalNavContactModal';
import GlobalNavDesktop from './components/GlobalNavDesktop';
import GlobalNavMobile from './components/GlobalNavMobile';

const defaultLinks = [
  {
    name: 'Inbox',
    href: routes.inbox.root,
    icon: IconInboxOutline,
    iconActive: IconInboxFilled,
  },
  {
    name: 'Media',
    href: routes.media.root,
    icon: IconMediaStacked,
    iconActive: IconMediaStackedSolid,
  },
];

export type GlobalNavLinks = Array<
  typeof defaultLinks[number] & { exact?: boolean }
>;

export default function GlobalNav() {
  const publisher = useCurrentPublisher();
  const [isContactModalOpen, setIsContactModelOpen] = useState(false);
  useBodyClassName(publisher ? 'global-nav-offset' : '');
  const { isMobile } = useIsMobile();
  const suggester = useCurrentSuggester();

  const showContactLink =
    !!suggester?.twilioNumbers?.length ||
    !!suggester?.parent?.twilioNumbers?.length;

  if (!publisher) return null;

  let links = defaultLinks;
  if (publisher.athlete || publisher.athleteEligible) {
    links = [
      ...defaultLinks,
      {
        name: 'Athlete',
        href: routes.athlete.root,
        icon: IconAthleteOutline,
        iconActive: IconAthleteFilled,
      },
    ];
  }

  return (
    <>
      {createElement(isMobile ? GlobalNavMobile : GlobalNavDesktop, {
        publisher,
        links,
        showContactLink,
        isContactModalOpen,
        onClickContact: () => setIsContactModelOpen(true),
      })}

      <GlobalNavContactModal
        isOpen={isContactModalOpen}
        onRequestClose={() => setIsContactModelOpen(false)}
        suggester={suggester}
      />
    </>
  );
}
