import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import MediaUploadStatus from 'types/MediaUploadStatus';

export type ExtendedFile = {
  fileUrl: string;
  name: string;
  type: string;
  size: number;
  status: MediaUploadStatus;
  progress: number;
  attachmentId?: number;
};

interface UploadMediaState {
  mediaItems: ExtendedFile[];
  currentUploads: ExtendedFile[];
  completedUploads: ExtendedFile[];
  uploadQueue: ExtendedFile[];
  isProcessingUploads: boolean;
  isUploadsComplete: boolean;
}

const initialState: UploadMediaState = {
  currentUploads: [],
  completedUploads: [],
  uploadQueue: [],
  mediaItems: [],
  isProcessingUploads: false,
  isUploadsComplete: false,
};

const directUploadMediaSlice = createSlice({
  name: 'uploadMedia',
  initialState,
  reducers: {
    setMediaItems(state, action: PayloadAction<ExtendedFile[]>) {
      state.mediaItems = action.payload;
    },
    setCurrentUploads(state, action: PayloadAction<ExtendedFile[]>) {
      state.currentUploads = action.payload;
    },
    updateCurrentUploads(state, action: PayloadAction<ExtendedFile>) {
      state.currentUploads = state.currentUploads.filter(
        (item) => item.name !== action.payload.name
      );
    },
    setCompletedUploads(state, action: PayloadAction<ExtendedFile>) {
      state.completedUploads.push(action.payload);
    },
    setUploadQueue(state, action: PayloadAction<ExtendedFile[]>) {
      state.uploadQueue = action.payload;
    },
    setIsProcessingUploads(state, action: PayloadAction<boolean>) {
      state.isProcessingUploads = action.payload;
    },
    setUploadsComplete(state, action: PayloadAction<boolean>) {
      state.isUploadsComplete = action.payload;
    },
    updateMediaItem(
      state,
      action: PayloadAction<{
        fileName: string;
        newStatus: MediaUploadStatus;
        progress?: number;
        attachmentId?: number;
      }>
    ) {
      const { fileName, newStatus, progress, attachmentId } = action.payload;
      state.mediaItems = state.mediaItems.map((item) =>
        item.name === fileName
          ? {
              ...item,
              status: newStatus,
              progress: progress || item.progress,
              attachmentId: attachmentId || item.attachmentId,
            }
          : item
      );
    },
    updateMediaItemByAttachmentId(
      state,
      action: PayloadAction<{
        attachmentId: number;
        newStatus: MediaUploadStatus;
      }>
    ) {
      const { attachmentId, newStatus } = action.payload;
      state.mediaItems = state.mediaItems.map((item) =>
        item.attachmentId === attachmentId
          ? {
              ...item,
              status: newStatus,
            }
          : item
      );
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  setMediaItems,
  setCurrentUploads,
  setCompletedUploads,
  setUploadQueue,
  setIsProcessingUploads,
  setUploadsComplete,
  updateCurrentUploads,
  updateMediaItem,
  updateMediaItemByAttachmentId,
  resetState,
} = directUploadMediaSlice.actions;

export default directUploadMediaSlice.reducer;
